@import "styles/colors";
@import "styles/media";

.form {
  width: 100%;
  max-width: 376px;
  position: relative;
}

.form__btn {
  margin-top: 20px;
}

.form__checkbox {
  margin: 0 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-phone-only {
    margin: 0 0 30px 15px;
  }
}

.form__comfirm-message {
  color: #64ad57;
  text-align: center;
  margin-bottom: 15px;
  display: block;
}

.form__forgot {
  font-size: 0.8em;
  color: $second-font;
}

.form__error {
  position: absolute;
  left: 40px;
  top: -20px;
  color: $danger;
  font-size: 0.9em;
}

.form__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1em;

  &--text {
    flex-basis: 284px;
    font-size: 1.1em;
    line-height: 2em;
    color: $green;

    @include for-tablet {
      flex-basis: 220px;
    }

    @include for-phone-only {
      margin-left: 20px;
      flex-basis: auto;
    }
  }

  &--field {
    flex-basis: 396px;

    @include for-tablet {
      flex-basis: 340px;
    }

    @include for-phone-only {
      flex-basis: auto;
      width: 100%;
    }
  }

  @include for-phone-only {
    margin-bottom: 0;
  }
}

.form__message {
  height: 20px;
  padding: 0;
  margin: 0;

  position: relative;
  padding-left: 312px;
  font-size: 1em;
  margin-bottom: 15px;

  &-error {
    color: $danger;
  }

  &-response {
    color: $base-color;
  }

  @include for-phone-only {
    margin-bottom: 0;
    padding-left: 0;
  }
}

.item-btn {
  display: flex;
  justify-self: flex-end;
}

.btn-wrapper {
  margin-left: 8px;
  display: flex;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.rooms {
  justify-content: flex-end;
}

@import "styles/colors";
@import "styles/media";

.input-wrapper {
  padding: 0 0 21px;
  position: relative;
  color: $danger;
  width: 100%;

  &-simple {
    padding: 0 0 12px;
    position: relative;
    color: $danger;
    width: 100%;

    @include for-phone-only {
      padding: 0 0 8px;
    }
  }
}

.field__error {
  position: absolute;
  bottom: 5px;
  left: 40px;
  font-size: 0.8em;

  &-simple {
    bottom: -5px;
    position: absolute;
    left: 27px;
    font-size: 0.8em;
  }
  @include for-phone-only {
    left: 20px;
  }
}

.field {
  width: 100%;
  position: relative;
  border: 0.5px solid $aside-text;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 1.1em;
  display: flex;
  align-items: center;

  background: $white;
}

.label {
  box-shadow: 0px 4px 16px rgba(39, 92, 29, 0.05);
  padding: 0.7em 32px;

  @include for-phone-only {
    padding: 0.7em 24px;
  }

  @include for-phone-height {
    padding: 10px 24px;
  }
}

.simple {
  padding: 12px 24px;

  @include for-phone-only {
    padding: 6px 16px;
  }
}

.field-error {
  border: 0.5px solid $danger;
}

.field__label {
  position: absolute;
  color: $second-font;
  display: block;
  cursor: text;
  top: 2px;
  left: 77px;
  transition: 0.2s;
  font-size: 0.7em;
  transform: translateY(0);

  @include for-phone-only {
    left: 66px;
  }
}

.field__input {
  width: 100%;
  font-size: 1.1em;
  color: $second-font;

  &::placeholder {
    color: transparent;
    user-select: none;
  }

  &:placeholder-shown ~ .field__label {
    font-size: 1.1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    ~ .field__label {
      top: 2px;
      font-size: 0.7em;
      transform: translateY(0);
    }
  }
}

.field__input-simple {
  width: 100%;
  font-size: 1.1em;
  color: $second-font;
  line-height: 1.2em;

  &::placeholder {
    color: rgba($second-font, 0.6);
  }

  &:disabled {
    // color: rgba($black, 0.4);
  }
  @include for-phone-only {
    font-size: 1em;
  }
}

.field__icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;

  @include for-phone-only {
    padding-right: 12px;
  }
}

.disabled {
  background: rgba($black, 0.05);
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: 750px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1340px) {
    @content;
  }
}

@mixin for-phone-height {
  @media (max-height: 666px) {
    @content;
  }
}

@import "styles/colors";
@import "styles/media";

.calendar {
  margin: 10px 0;

  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.fc-time-grid .fc-slats td {
  height: 100px;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
  min-width: 650px;
}

.fc-col-header {
  tbody {
    th {
      border-right: none;
      border-left: none;
    }
  }
}

.fc-body .fc-row {
  min-height: 45px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $white;
}
.fc-daygrid-day-frame:before,
.fc-daygrid-day-events:before,
.fc-daygrid-event-harness:before,
.fc-daygrid-event-harness:after {
  content: none;
}
.fc .fc-toolbar {
  justify-content: center;
  display: none;
}

.fc .fc-more-popover .fc-popover-body {
  padding: 0;
}

.fc .fc-button-primary {
  background-color: $green;
  border-color: transparent;
  transition: 0.3s;

  &:hover {
    background-color: rgba($base-color, 0.8);
    border-color: transparent;
  }

  &:active,
  :hover,
  :focus {
    outline: 0;
    outline-offset: 0;
    box-shadow: none;
  }
}

.fc-day:hover,
.fc-today:hover {
  cursor: pointer;
}

.fc-other-month {
  pointer-events: none;
}

.fc-event-container {
  pointer-events: none;
}

.event-status {
  margin-bottom: 10px;
  background-color: transparent;
  text-transform: capitalize;
  font-size: 1em;
}
.fc-toolbar-chunk {
  margin-right: 10px;
}
.fc-toolbar-title {
  width: 250px;
  display: flex;
  justify-content: center;
}
.fc-daygrid-dot-event .fc-event-title {
  font-weight: 400;
}

.fc tbody {
  height: 1px;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}

.fc .fc-daygrid-day-number {
  padding: 0;
  width: 100%;
}
// week day name
.fc .fc-col-header-cell-cushion {
  padding: 10px 0;
  font-size: 0.9em;
}

.fc-theme-standard .fc-popover-header {
  display: none;
}

.fc .fc-more-popover {
  border-radius: 4px;
}

.fc-resized-row {
  height: 104px !important;
}

.fc-day-grid-event .fc-content {
  white-space: nowrap;
  overflow: hidden;
  height: 70px;
}

.fc-daygrid-more-link {
  color: #777;
  width: 100%;
  margin-left: 4px;
  padding: 10px;
  border-radius: 1px;
  transition: 0.2s;

  &:hover {
    color: #333;
  }
  @include for-tablet {
    display: none;
  }
}

.fc-scrollgrid-sync-table {
  border-spacing: 4px !important;
  border-collapse: separate !important;
}

.fc-daygrid-day-frame {
  border: 0.5px solid #e0e0e0 !important;
  border-radius: 4px;
}

// event

.event {
  position: relative;
  pointer-events: none;
  color: $black;
  font-size: 0.9em;
  padding-left: 5px;
}

.title-event {
  color: $green;
  text-transform: uppercase;
  padding: 8px 0 8px 5px !important;

  div {
    font-weight: bold !important;
  }
}

.table-error {
  color: #eb5757;
  margin: 20px;
}

.fc .fc-daygrid-event {
  white-space: normal;
}

@import "styles/colors";
@import "styles/media";

.not-found {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background;
  color: $black;
  padding: 0 20px;
  box-sizing: border-box;

  &__title {
    font-size: 4em;

    @include for-phone-only {
      font-size: 3em;
    }
  }

  &__text {
    font-size: 1.6em;
    line-height: 1.6em;

    @include for-phone-only {
      font-size: 1em;
      line-height: 1.5em;
    }
  }

  @include for-phone-only {
    background: none;
  }
}

@import "styles/fonts";
@import "styles/media";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

img {
  user-select: none;
}

button,
input {
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
}

input {
  box-sizing: border-box;
  background: transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.fullpage {
  height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.content {
  margin-left: 240px;
  width: 100%;

  @include for-desktop-up {
    margin-left: 0;
    margin-top: 60px;
  }
}

.container {
  padding: 20px 20px 40px;
  position: relative;
}

.block {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include for-phone-only {
    margin-bottom: 50px;
  }
}

.max-container {
  max-width: 1160px;
  margin: 0 auto 0;
}

.select__menu {
  z-index: 2 !important;
}

.without-menu {
  font-size: 1.1em;
  color: #8a9268;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head-container {
  position: relative;

  &__stats {
    position: absolute;
    top: 16px;
    right: 0;

    @include for-desktop-up {
      top: 8px;
    }
    @include for-tablet-landscape-up {
      top: -90px;
    }
  }
}

@page {
  margin: 1cm;
  @top-center {
    content: element(header, first-except);
  }
  @bottom-left {
    content: element(footer);
  }
}
